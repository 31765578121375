import React from "react";
import { Autocomplete, Paper, SvgIcon, TextField, Tooltip, IconButton } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineCard } from "./TimelineCard";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { D } from "../include";
import ReactToPrint from "react-to-print";
import ComputerIcon from '@mui/icons-material/Computer';
import Icon from '@mui/material/Icon';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {DialogActions} from "@mui/material";
import {BrowserUpdated} from '@mui/icons-material';
import { saveAs } from 'file-saver'

const oicon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const filterIt = (terms, arr) => {
  if ("" === terms || terms.length < 3) return arr;
  var words = terms.match(/"[^"]+"|\w+/g);

  words = words.map(val => val.replace(/"/g, ""));
  return arr.filter((a) => {
    const v = Object.values(a);
    const f = JSON.stringify(v).toLowerCase();

    return words.every(val => f.includes(val));
  });
};


const style = {
  overflow: 'hidden',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '70vw',
  minWidth: '50vw',
  minHeight: '10vh',
  maxHeight: '60vh',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem',
  position: "absolute"
};

export class TimelineClass extends React.PureComponent {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      factFilters: [],
      timelineBody: [],
      currentPage: 1,
      postsPerPage: 10,
      card: false,
      closeCard: true,
      timelineResponse: [],
      fatos: [],
      parseInit: 0,
      ocorrenciasModal: false,
      factFiltersModal:[],
      ocorrencias1Modal: false
    };
  };

  OnClickCard = e => {
    this.setState({ card: true });
    this.setState({ doc_id: e })
  };

  OnCloseCard = () => {
    this.setState({ card: false })
  };

  OnCloseModal = () => {
    this.setState({ ocorrenciasModal: false })
  };
  
  OnCloseModal1 = () => {
    this.setState({ ocorrencias1Modal: false })
  };

  getDocId = e => {
    this.setState({ doc_id: e })
  };

  getTimelineBody = async (e) => {
    if (!e) return ;
    let body = [];
    e.map(element => {
      this.state.fatos.map(objs => {
        if (objs.fato_id === element.fato_id) {
          if (objs.ocorrencias !== element.ocorrencias) objs.ocorrencias = element.ocorrencias
        }
      });
      if (!(element.fato_id in this.state.fatos)) this.state.fatos.push(element)
    });

    const newFatos = this.state.fatos.filter((object, index) => {
      return index === this.state.fatos.findIndex(value => object.fato_id === value.fato_id)
    });

    let events = [];
    this.setState({ fatos: newFatos });
    
    this.state.fatos.length > 0 ?  events = this.state.fatos.map(valoresOcorrencias => valoresOcorrencias.ocorrencias).reduce((ocorrencias, values) => ocorrencias.concat(values, [])) : events = []

    const data = await this.props.api.get(`timeline?eventos=${JSON.stringify(events)}`);
    body = data.map(e => ({
      id: e.id,
      quantificador_maquina: e.quantificador_maquina,
      nome_arquivo: e.nome_arquivo,
      doc_id: e.document_id,
      date: e.created_at,
      fato: e.qualificator,
      icon: e.icon_name,
      dtaprox: e.dtaprox,
      data: e.dtaprox,
      fatos: e.fatos_do_documento,
      status: e.status,
      quantificador_homem: e.quantificador_homem
    }));
    let body2 = [];
    let value = [];
    let dataInicio1 = (this.props.dataInicio.split("/").reverse().join("/"));
    let dataFim1 = (this.props.dataFim.split("/").reverse().join("/"));

    for (let i = 0; i < body.length; i++) {
      value.push(body[i].dtaprox);
      value[i] = (value[i].split("/").reverse().join("/"));
      value[i] = Date.parse(value[i]);
      body[i].dtaprox = value[i];

      if (body[i].status === 'processado') {
        body[i].status_icon = 'check_circle'
      } else if (body[i].status === 'não processado') {
        body[i].status_icon = 'sync'
      } else if (body[i].status === 'em processamento') {
        body[i].status_icon = 'sync_disabled'
      }
    };

    body.sort(function (a, b) {
      if (a.dtaprox > b.dtaprox) {
        return 1
      }
      if (a.dtaprox < b.dtaprox) {
        return -1
      }
      return 0
    }).reverse();

    body.forEach(value => {
      value.fatos.forEach(value => {
        if (value.qualificator === "Nome de Embarcação") {
          value.quantificador_homem !== null ? value.name = value.quantificador_homem : value.tipoDocumento = value.quantificador_maquina
        } else if (value.qualificator === "Tipo de Documento") {
          value.quantificador_homem !== null ? value.name = value.quantificador_homem : value.tipoDocumento = value.quantificador_maquina
        } else if (value.qualificator === "Fornecedor") {
          value.quantificador_homem !== null ? value.name = value.quantificador_homem : value.fornecedor = value.quantificador_maquina
        }
      })
    });

    for (let i = 0; i < body.length; i++) {
      if (body[i].dtaprox >= Date.parse(dataInicio1) && body[i].dtaprox <= Date.parse(dataFim1)) {
        body2.push(body[i])
      }
    };

    body = body2
    this.setState({ timelineBody: body })
    this.setState({ timelineResponse: body })

    if (this.state.timelineBody.length === 0) {
      this.setState({ currentPage: 1 })
    };
  };
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.factId !== prevProps.factId) {
      this.props.api.get(`Timeline/Fatos/${JSON.stringify(this.props.factId)}`).then(r => {
        this.setState({factFilters: r})
      });
      
      const newFatos = this.state.fatos.filter((object, index) => {
        return this.props.factId.some(continuesId => object.fato_id === continuesId)
      });
      
      this.setState({ fatos: newFatos },
        () => {
          this.getTimelineBody(this.state.fatos)
        })
    } else if (this.props.factIdOpen !== prevProps.factIdOpen) {
      this.props.api.get(`Timeline/Fatos/${JSON.stringify(this.props.factIdOpen)}`).then(r => {
        this.setState({factFiltersModal: r});
        this.setState({ocorrencias1Modal: true})
      });
    } else if (this.state.timelineBody < prevState.timelineBody) {
      this.setState({ currentPage: 1 })
    }
    else if (this.props.dataInicio !== prevProps.dataInicio) {
      this.getTimelineBody(this.state.fatos)

    } else if (this.state.timelineBody === 0 || this.state.timelineBody < prevState.timelineBody) {
      this.setState({ timelineBody: prevState.timelineBody })
    } else if (this.props.dataFim !== prevProps.dataFim) {
      this.getTimelineBody(this.state.fatos)
    } else if (this.props.search !== prevProps.search) {
      this.filterList(this.props.search)
    } 
    if (this.props.selectTimelineParameters !== prevProps.selectTimelineParameters) {
      this.getTimelineBody(this.props.selectTimelineParameters)
    }
   if (this.state.fatos !== prevState.fatos) {
    const selectFactsName = this.props.selectFactsName ?? [];
    const timelineParameters = this.state.fatos ?? [];
      
      this.props.api.post(`/users/selectParametersTimeline?user_id=${this.props.user.id}&selectFactName=${JSON.stringify(selectFactsName)}&timelineParameters=${JSON.stringify(timelineParameters)}`)  
    }
  }

  filterList = ({ target }) => {
    const searchQuery = target.value.toLowerCase();
    const updatedList = filterIt(searchQuery, this.state.timelineResponse);
    if (!target.value) {
      this.setState({ timelineBody: this.state.timelineResponse })
    }
    if (updatedList.length !== 0) {
      this.setState({ timelineBody: updatedList });
    }
  };

  findMatchingValues(fato_id) {
    const chooseFato = this.state.fatos.find(chooseFatoId => chooseFatoId.fato_id === fato_id);

    if (chooseFato !== undefined) {
      return chooseFato.ocorrencias
    };
  };

	handleMergePDFsAndDownload = () => {
		let ids = []
		for (let i in this.state.timelineBody)
			ids.push(this.state.timelineBody[i].doc_id)
		if(ids.length === 0)
			this.props.alert('Operação com Linha do Tempo Vazia')
		else{
			this.props.api.post('/mediateca/mergeanddownload', { docs: ids }, { responseType: 'arraybuffer' }).then(response => {
				const blob = new Blob([response], { type: 'application/pdf' })
				saveAs(blob, "MergedDocs.pdf")
            })
		}
	};

  render() {

    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;

    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;

    const count = Math.ceil(this.state.timelineBody.length / this.state.postsPerPage);
    const _DATA = this.state.timelineBody.slice(indexOfFirstPost, indexOfLastPost);

    const handleChange = (e, p) => {
      this.setState({ currentPage: p });
    };

    return (
      <>
      {this.state.ocorrencias1Modal &&
                  <Modal open={this.state.ocorrencias1Modal}
                    onClose={this.OnCloseModal1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box style={style} sx={{ bgcolor: 'white' }}>
                      <header style={{ paddingBottom: "6px", borderBottom: "1px solid gray", flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <h2 style={{ padding: 0, margin: 0, color: "#3f3f3f" }}>Ocorrências Observadas</h2>
                        </div>
                        <div style={{ textAlign: 'end', padding: 0, margin: 0 }}>
                          <CloseIcon onClick={() => this.OnCloseModal1()} sx={{
                            ":hover": {
                              color: 'grey', backgrounColor: 'white'
                            }
                          }} />
                        </div>
                      </header>
                      <div style={{ display: 'flex', flexDirection: "column", position: 'relative', height: 'calc(100% - 72px)' }}>

                        {
                          this.state.factFiltersModal &&
                          this.state.factFiltersModal.map(element => (
                            <Autocomplete
                              key={element.fato_id}
                              multiple={true}
                              id="checkboxes-tags-demo"
                              options={element.quantificador_maquina}
                              value={this.findMatchingValues(element.fato_id) || []}
                              getOptionLabel={(options) => options || ''}
                              // isOptionEqualToValue={(option, value) =>
                              //   option === value
                              // }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={oicon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected} />
                                  {option}
                                </li>
                              )}
                              style={{ width: "100%", paddingBottom: '10px', paddingTop: '15px', marginTop: '4px' }}
                              renderInput={(params) => (
                                <TextField {...params} label={element.qualificator} placeholder="" />
                              )}
                              onChange={(e, value) => {
                                this.getTimelineBody([{ fato_id: element.fato_id, ocorrencias: value }]);
                              }} />
                          ))
                        }
                      </div>
                      <DialogActions>
                        <Button style={{width:'100px'}} variant="outlined" color="primary" onClick={() => this.OnCloseModal1()}>OK</Button>
                      </DialogActions>
                    </Box>
                  </Modal>
                }
        <Stack display='' sx={{ "@media print": { displayPrint: "none" }, }}>
              <div>
                <Button sx={{ marginRight: '10px' }} variant='outlined' onClick={() => this.setState({ ocorrenciasModal: true })}>Ocorrências escolhidas</Button>
                {this.state.ocorrenciasModal &&
                  <Modal open={this.state.ocorrenciasModal}
                    onClose={this.OnCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box style={style} sx={{ bgcolor: 'white' }}>
                      <header style={{ paddingBottom: "6px", borderBottom: "1px solid gray", flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <h2 style={{ padding: 0, margin: 0, color: "#3f3f3f" }}>Ocorrências Observadas</h2>
                        </div>
                        <div style={{ textAlign: 'end', padding: 0, margin: 0 }}>
                          <CloseIcon onClick={() => this.OnCloseModal()} sx={{
                            ":hover": {
                              color: 'grey', backgrounColor: 'white'
                            }
                          }} />
                        </div>
                      </header>
                      <div style={{ display: 'flex', flexDirection: "column", position: 'relative', overflowY: 'scroll', height: 'calc(100% - 72px)' }}>

                        {
                          this.state.factFilters &&
                          this.state.factFilters.map(element => (
                            <Autocomplete
                              key={element.fato_id}
                              multiple={true}
                              id="checkboxes-tags-demo"
                              options={element.quantificador_maquina}
                              value={this.findMatchingValues(element.fato_id) || []}
                              getOptionLabel={(options) => options || ''}
                              // isOptionEqualToValue={(option, value) =>
                              //   option === value
                              // }
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={oicon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected} />
                                  {option}
                                </li>
                              )}
                              style={{ width: "100%", paddingBottom: '10px', paddingTop: '15px', marginTop: '4px' }}
                              renderInput={(params) => (
                                <TextField {...params} label={element.qualificator} placeholder="" />
                              )}
                              onChange={(e, value) => {
                                this.getTimelineBody([{ fato_id: element.fato_id, ocorrencias: value }]);
                              }} />
                          ))
                        }
                      </div>
                      <DialogActions>
                        <Button style={{width:'100px'}} variant="outlined" color="primary" onClick={() => this.OnCloseModal()}>OK</Button>
                      </DialogActions>
                    </Box>
                  </Modal>
                }
              </div>
        </Stack>
        <div>
          <div style={{ "@media print": { display: "block" } }} >
            <ReactToPrint trigger={() => <Tooltip title="Imprimir Linha do Tempo" arrow><IconButton><LocalPrintshopOutlinedIcon /></IconButton></Tooltip>}
              content={() => this.componentRef}
            >
            </ReactToPrint>
			
			<Tooltip title='Exportar PDF' arrow>
				<IconButton sx={{ marginLeft: '12px' }} onClick={ () => this.handleMergePDFsAndDownload()}>
					<BrowserUpdated />
				</IconButton>
			</Tooltip>
			

            <div ref={el => (this.componentRef = el)}>

              <Timeline position="alternate">
                {
                  _DATA &&
                  _DATA.map(element =>
                    <TimelineItem key={element.id}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ bgcolor: 'primary.main' }} >
                          <SvgIcon component={ComputerIcon} />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent style={{ width: "40%", }}>
                        <D>
                          <Button onClick={() => { this.OnClickCard(element.doc_id) }}>
                            <Paper elevation={3} sx={{
                              maxWidth: '400px', maxHeigh: '420px', minHeight: '70px',
                            }} >
                              <Stack>
                                <div style={{ display: 'flex' }}>
                                  <Tooltip title={element.status} arrow>

                                    <Icon style={{ color: '#aaa' }}>{element.status_icon}</Icon>
                                  </Tooltip>
                                </div>

                                {element && element.fatos.map(fato =>
                                  <div key={fato.fato_id} style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', color: 'blue' }}>
                                    <span> {fato.name}</span>
                                    <span>{fato.tipoDocumento}</span>
                                    <span>{fato.fornecedor}</span>
                                  </div>
                                )}

                                <Stack direction='column' spacing={1} sx={{ display: 'flex' }}>
                                  <span style={{ textAlign: 'center' }}>{element.data}
                                  </span>
                                  <div style={{ display: 'flex', justifyContent: 'center', padding: '5px', flexWrap: 'wrap', gap: "5px", color: '#aaa' }}>
                                    {element &&
                                      element.fatos.map(fato =>
                                        <Tooltip key={fato.fato_id} title={fato.qualificator} arrow>
                                          <Icon style={{ color: '#aaa' }}>{fato.icon_name}</Icon>
                                        </Tooltip>
                                      )}
                                  </div>
                                </Stack>
                              </Stack>
                            </Paper>
                          </Button>
                        </D>
                      </TimelineContent>
                    </TimelineItem>
                  )
                }
              </Timeline>
            </div>
          </div>
          <Pagination
            count={count}
            size="large"
            page={this.state.currentPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
        {
          this.state.card &&
          <>
            <TimelineCard doc_id={this.state.doc_id} api={this.props.api}
              stateCard={this.state.card} onCloseCard={this.OnCloseCard}
            />
          </>
        }

      </>)
  }
}                   
