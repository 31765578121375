import React from 'react'
import { TextField, Typography, CircularProgress, InputBase, TableCell, TableRow, TableSortLabel, MenuItem, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableSortLabelClasses} from '@mui/material/TableSortLabel'
import { Search } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { withFormsy } from 'formsy-react'
import _ from 'underscore'
// const env = 'tribunal'
const env = 'wilsonsons'
const host = 'https://apiws.ai2bizlab.com.br/'
//const host = 'http://10.21.45.167:7001/'
const labels = {
  tribunal: {
    navImg: '/static/wscover.webp',
    coverImg: '/static/wscover.webp',
    businessLabel: 'Tribunal',
    divisionLabel: 'Vara',
    objectLabel: 'Processos',
    objectIDLabel: 'Número do processo',
    ongoingLabel: 'Em trâmite',
    businessNotFound: 'Nenhum tribunal',
    divisionNotFound: 'Nenhuma vara',
    groupLabel: 'Grupo'
  },
  wilsonsons: {
    navImg: '/static/wilsonsons.png',
    coverImg: '/static/wscover.webp',
    businessLabel: 'Empresa',
    divisionLabel: 'Departamento',
    objectLabel: 'Documentos',
    objectIDLabel: 'Identificaçao do documento',
    ongoingLabel: 'Em processamento',
    businessNotFound: 'Nenhuma empresa',
    divisionNotFound: 'Nenhum departamento',
    groupLabel: 'Filial-Grupo'
  }
}
export const C = {
  appName: 'Julia',
  appVersion: 'v3.3.5',
  baseURL: host,
  navImg: labels[env].navImg,
  coverImg: labels[env].coverImg,
  businessLabel: labels[env].businessLabel,
  divisionLabel: labels[env].divisionLabel,
  objectLabel: labels[env].objectLabel,
  objectIDLabel: labels[env].objectIDLabel,
  ongoingLabel: labels[env].ongoingLabel,
  businessNotFound: labels[env].businessNotFound,
  divisionNotFound: labels[env].divisionNotFound,
  groupLabel: labels[env].groupLabel
}
export class ConfirmDialog extends React.Component {
  render() {
    return (
      <D className="col e aF pa">
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent style={{ minWidth: '25vw' }}>
          {!!this.props.msg &&
            <>
              <DialogContentText>{this.props.msg}</DialogContentText>
              <D h="1rem" />
            </>
          }
          {!!this.props.reg &&
            <>
              <DialogContentText align="center" variant="h6">{this.props.reg}</DialogContentText>
              <D h="1rem" />
            </>
          }
          <>{this.props.children}</>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={this.props.onCancel}>{typeof this.props.onConfirm === 'function' ? 'Cancelar' : 'Fechar'}</Button>
          {typeof this.props.onConfirm === 'function' && <Button variant="outlined" color="primary" onClick={this.props.onConfirm}>Confirmar</Button>}
        </DialogActions>
      </D>
    )
  }
}
export class MessageDialog extends React.Component {
  render() {
    return (
      <D className="col e aF pa">
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent style={{ minWidth: '25vw' }}>
          {!!this.props.msg &&
            <>
              <DialogContentText>{this.props.msg}</DialogContentText>
              <D h="1rem" />
            </>
          }
          {!!this.props.reg &&
            <>
              <DialogContentText align="center" variant="h6">{this.props.reg}</DialogContentText>
              <D h="1rem" />
            </>
          }
          <>{this.props.children}</>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={this.props.onOk}>{typeof this.props.onOk === 'function' ? 'OK' : 'Fechar'}</Button>
        </DialogActions>
      </D>
    )
  }
}
export const TCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#424242',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    wordWrap: 'normal'
  },
}))
export const TSortLabel = styled(TableSortLabel)(({ theme }) => ({
   [`&.${tableSortLabelClasses.root}`]: {
       color: theme.palette.common.white,
       "& $icon": {
            color: theme.palette.common.white
       },
       "&:hover": {
         color: theme.palette.common.white,
         '&& $icon': {
             color: theme.palette.common.white
          },
       },
       '&$active': {
         color: theme.palette.common.white,
         '&& $icon': {
            color: theme.palette.common.white
          },
       }
   },
   [`&.${tableSortLabelClasses.active}`]: {},
   [`& .${tableSortLabelClasses.icon}`]: {
       color: theme.palette.common.white,
       '& path': {
            fill: '#eee'
        }
   }
}))
export const TRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))
export class SearchField extends React.Component {
  render() {
    return (
      <D
        className="row e aS"
        style={{
          minWidth: '100px',
          maxWidth: '500px',
          padding: '3px 7px 2px 7px',
          borderRadius: '0.5rem',
          backgroundColor: '#eeeeee',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        }}>
        <Search />
        <D size="1rem" />
        <InputBase
          style={{ color: 'inherit', width: '100%' }}
          placeholder="Buscar ..."
          value={this.props.value}
          onChange={e => this.props.onChange(e.target.value)} />
      </D>
    )
  }
}
export class SearchFieldMediateca extends React.Component {
  render() {
    return (
      <D
        className="row e aS"
        style={{
          minWidth: '100px',
          maxWidth: '500px',
          padding: '3px 7px 2px 7px',
          borderRadius: '0.5rem',
          backgroundColor: '#eeeeee',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        }}>
        <D size="1rem" />
        <InputBase
          style={{ color: 'inherit', width: '100%' }}
          placeholder="Buscar ..."
          value={this.props.value}
          onChange={e => this.props.onChange(e.target.value)} />
      </D>
    )
  }
}
export class Input extends React.Component {
  constructor(props) {
    super(props)
    this.importedProps = _.pick(this.props, [
      'autoComplete',
      'autoFocus',
      'children',
      'className',
      'defaultValue',
      'disabled',
      'FormHelperTextProps',
      'fullWidth',
      'id',
      'InputLabelProps',
      'inputProps',
      'InputProps',
      'inputRef',
      'label',
      'multiline',
      'name',
      'onBlur',
      'onChange',
      'onFocus',
      'placeholder',
      'required',
      'rows',
      'rowsMax',
      'select',
      'SelectProps',
      'type',
      'color'
    ])
  }
  render() {
    const errorMessage = this.props.errorMessage
    return (
      <D className="col w aF">
        <TextField
          {...this.importedProps}
          variant={!!this.props.variant ? this.props.variant : 'outlined'}
          margin={!!this.props.margin ? this.props.margin : 'normal'}
          onChange={e => this.props.onChange(e.currentTarget.value)} 
          value={!!this.props.value ? this.props.value : ''} />
        {!!errorMessage &&
          <>
            <D h="3px" />
            <D className="row w aC">
              <Typography variant="caption">{errorMessage}</Typography>
            </D>
            <D h="10px" />
          </>
        }
      </D>
    )
  }
}
export class CustomSelect extends React.Component {
  render() {
    return (
      <TextField
      defaultValue={''}
        value={this.props.options.includes(this.props.value) ? this.props.value : ''} 
        label={this.props.label}
        type='text'
        InputLabelProps={this.props.InputLabelProps}
        className="Input"
        inputProps={{ className: this.props.className }}
        style={{ ...this.props.style, width: !!this.props.width ? this.props.width : '100%' }}
        placeholder={this.props.placeholder}
        disabled={!!this.props.disabled ? true : false}
        variant="outlined"
        size={!!this.props.heightSize ? this.props.heightSize : 'medium'}
        color="primary"
        margin={!!this.props.margin ? this.props.margin : 'normal'}
        select
        onChange={e => this.props.onChange(e.target.value)}>
        {typeof this.props.options === 'object' && this.props.options.length > 0 &&
         this.props.options ? this.props.options.map(o => {
            if (typeof o === 'object') {
              const [k, v] = Object.entries(o)[0]
              return <MenuItem key={k} value={k}>{v}</MenuItem>
            } else {
              return <MenuItem key={o} value={o}>{o}</MenuItem>
            }
          }) : <MenuItem></MenuItem>
        }
      </TextField>
    )
  }
}
export class CenterContent extends React.Component {
  render() {
    return (
      <D e className={'row aF ' + (!!this.props.background ? this.props.background : '')}>
        {/* <D e className="hide-lg" /> */}
        <D e className="hide-xlg" />
        <D e />
        <div onClick={this.props.onClick} onChange={this.props.onChange} onMouseDown={this.props.onMouseDown} id={this.props.id} className={this.props.className} style={{ ...this.props.style, ...{ flex: !!this.props.p ? this.props.p : 1, maxWidth: this.props.size } }}>
          {this.props.children}
        </div>
        <D e />
        <D e className="hide-xlg" />
        {/* <D e className="hide-lg" /> */}
      </D>
    )
  }
}
export class Loading extends React.Component {
  render() {
    return (
      <D className="row e aC" style={this.props.style}>
        <CircularProgress />
      </D>
    )
  }
}
export class D extends React.Component {
  render() {
    return (
      <div
        onClick={this.props.onClick}
        onChange={this.props.onChange}
        onMouseDown={this.props.onMouseDown}
        onMouseMove={this.props.onMouseMove}
        onMouseUp={this.props.onMouseUp}
        id={this.props.id}
        className={!!this.props.hide ? 'hide' : (!!this.props.row ? 'row ' : '') + (!!this.props.col ? 'col ' : '') + (!!this.props.e ? 'e ' : '') + (!!this.props.w ? 'w ' : '') + (!!this.props.cover ? 'coverImg ' : '') + (!!this.props.side ? 'sideImg ' : '') + (!!this.props.className ? this.props.className : '')}
        style={{
          ...(!this.props.p ? {} : { flex: this.props.p }),
          ...(!this.props.h ? {} : { minHeight: this.props.h }),
          ...(!this.props.size ? {} : { flexBasis: this.props.size, minWidth: this.props.size, maxWidth: this.props.size }),
          ...(!this.props.img ? {} : { backgroundImage: 'url("' + this.props.img + '")' }),
          ...this.props.style,
        }}>
        {!!this.props.imc ? <img alt="" src="/static/img/zero.png" className="icn-xmc" /> : null}
        {this.props.children}
      </div>
    )
  }
}
export const obj2form = d => {
  let l = new FormData()
  Object.entries(d).forEach(e => {
    const [k, v] = e
    l.append(k, v)
  })
  return l
}
const toHex = c => "#" + ((c[0] << 16) | (c[1] << 8) | c[2]).toString(16)
const toRgb = hex => {
  let r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return r ? [parseInt(r[1], 16), parseInt(r[2], 16), parseInt(r[3], 16)] : []
}
const interpolateColor = (c1, c2, factor) => {
  var result = c1.slice()
  for (var i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (c2[i] - c1[i]))
  }
  return result
}
export const getColors = (l, color1, color2) => {
  let stepFactor = 1 / (l - 1), inter = []
  color1 = toRgb(color1) //.match(/\d+/g).map(Number)
  color2 = toRgb(color2) //.match(/\d+/g).map(Number)
  for (var i = 0; i < l; i++) {
    inter.push(toHex(interpolateColor(color1, color2, stepFactor * i)))
  }
  return inter
}
export const getColorsByClass = (l, color1, color2) => {
    let myPalette = []
    let colors = [toHex([31,119,180]), toHex([255,127,14]), toHex([44,160,44]), toHex([214,39,40]), toHex([148,103,189]), toHex([140,86,75]), toHex([227,119,194]), toHex([127,127,127]), toHex([188,189,34]), toHex([23,190,207]), toHex([177,89,40])]
   for (var i = 0; i < l; i++) {
     myPalette.push(colors[i % colors.length])
   }
   return myPalette
 }
export const checker = (ss, sf) => !sf ? true : !!_.compact(sf.split(' ')).every(e => ss.includes(e))
export const openLink = t => window.open(t, '_blanck')
export const TextFieldFormsy = withFormsy(Input)
